import React from 'react';
import {evite} from 'evite';
import {ABTestContext} from './TestContext';

export const ABTest = (props) => {
  const {
    children,
    preConditions = true,
    exp,
    variation,
    original = false,
    isAlreadyInTest = false,
    defaultVariation = false,
  } = props;

  if (variation) {
    // this is to give support to send the variation as a prop of <ABTest />
    const isInTest =
      isAlreadyInTest ||
      (preConditions && evite.experiments.inVariation(exp, variation, defaultVariation));

    if (isInTest) {
      return children;
    }
    if (!original)
      console.error('ABTest: please supply a value for "original"! Nothing will be returned');
    return original;
  }
  // if variation is not sent, children must have TestVariation
  if (!preConditions && original) {
    return original;
  }

  const experiment =
    evite.experiments.getOrSetVariation(
      exp,
      defaultVariation,
      !preConditions // readOnly if preConditions is false which will return if variation is not chosen undefined
    ) || defaultVariation;

  return <ABTestContext.Provider value={experiment}>{children}</ABTestContext.Provider>;
};
